import React from 'react'
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import TwoColumn from "../../components/two-column"
import Process from "../../components/process"
import ComparisonTable from "../../components/comparison-table"
// import SectionSwiper from "../../components/section-swiper"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const bannerContent = {
  title: "Even if all you've got is a post-it, we'll help you build a business.",
  description: [
    {
      text: 'Billsby Pro Development Services help you to build the integrations and systems you need to power your subscription business, with billing by Billsby. From account management to bespoke workflows, our team can help.'
    }
  ],
  buttons: [
    {
      // url: '/',
      buttonText: 'Get in touch',
      buttonColor: 'orange',
      getInContactButton: true
      // intercom: true,
      // itercomTop: true
    }
  ],
  textColor: '#fff',
  backgroundColor: '#252422',
  backgroundImage: require('../../images/pro-develop-banner@2x.png'),
  backgroundPosition: 'right',
  image: 'pro-develop-banner.png',
  imageName: 'pro-development image',
  imagePosition: 'full-right',
  sectionProDevelopmentBanner: true,
}

const subscriptionContent = {
  title: "Building the systems you need for your susbcription business.",
  description: [
    {
      text: "If you don't know what an API, a web hook or a cron job is, the idea of setting up subscription billing software - even if it's as easy to setup as Billsby - might be intimidating. So if you want us to just do it all for you, we can. Our dedicated team are ready to support you and make your business goals a reality."
    },
    {
      text: "We'll help you to figure out how you want your business to run, connecting together all of your systems and tools and building the necessary custom integrations and systems you need to make things fly. We can even help you with custom sign-up and registration forms that integrate perfectly with Billsby, and then with your existing CRM, logistics and marketing systems."
    },
    {
      text: "The whole project is managed for you, and if you do want your developers to do some of the work, we'll work hand-in-hand, producing integration guides and ensuring everything works together perfectly once we're done."
    }
  ],
  image: 'pro-development-section-2.png',
  imageName: 'pro-development image',
  imagePosition: 'left',
  sectionSystem: true
}

const processContent = {
 title: 'Our simple, tried and tested process',
 cardsFirstBatch : [
   {
    title: "Discovery call",
    description: "We'll start with a quick call from a member of our professional services team to check your service is a fit for our product.",
    image: 'img-discover-call.png'
   },
   {
    title: "Solution call",
    description: "Your experienced project manager will present our end-to-end solution scope to you in a call - this part is usually pretty exciting!",
    image: 'img-solution-call.png'
   },
   {
    title: "50% upfront payment",
    description: "At this stage, we'll collect 50% of the fees for the project upfront. We don't commence work without this upfront payment.",
    image: 'img-upfront-payment.png'
   },
   {
    title: "Constant communication",
    description: "You'll get daily updates and be able to ask questions about the status of your project at any time through your Slack channel.",
    image: 'img-constant-communication.png'
   },
   {
    title: "Hosting and maintenance",
    description: "We stand behind our work. All hosting and maintenance is done for you for a low monthly and all-inclusive fee.",
    image: 'img-hosting-and-maintenance.png'
   }
 ],
 cardsSecondBatch : [
    {
      title: "NDA and handover",
      description: "We'll agree a two-way NDA to keep your ideas protected and ask you to share the commercial and technical details we need.",
      image: 'img-nda-and-handover.png'
    },  
    {
      title: "Project specification and estimation",
      description: "We'll get the entire project specified and estimated and then send our estimates out to you for review and sign-off.",
      image: 'img-project-specs-estimation.png',
    }, 
    {
      title: "Design, development and QA",
      description: "Our specialist teams, under the guidance of your project manager, will get to work delivering the project.",
      image: 'img-design-dev-qa.png'
    }, 
    {
      title: "Completion and handover",
      description: "Once the project is completed, and payment settled, we'll handover logins and source code, if you need it.",
      image: 'img-completion-and-handover.png'
    }, 
  ]
}

const fourthSectionContent = {
  title: "Built in protection from budget over-runs means no nasty surprises.",
  description: [
    {
      text: "If you've ever run a development project, you'll know no matter how good the estimation (and we're very good at estimation!) sometimes things take longer than expected. We're so confident in our ability to deliver projects on time and on budget, that we've got you covered for up to 20% more hours than we estimated upfront- so if we estimated a project would take 200 hours, but it actually takes 225 the 25 extra hours are on us."
    }
  ],
  image: 'pro-development-section-4.png',
  imageName: 'pro-development image',
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../images/pro-development-section-4@2x.png'),
  backgroundPosition: 'right',
  sectionProtection: true,
}

const comparisonTableContent = {
  title: 'How we stack up',
  description: "Without Billsby Pro Development Services, you'd find yourself using a job board to find a developer. On top of the overhead of running your project yourself, this option is often significantly more expensive too!",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: 'upwork.png',
      title: 'Upwork' 
    },
    {
      logo: 'toptal.png',
      title: 'Toptal' 
    },
    {
      logo: 'freelancer.png',
      title: 'Freelancer' 
    }
  ],
  body: [
    {
      title: 'Hourly Rate',
      content: [
        {
          itemContent: '$25'
        },
        {
          itemContent: '$10 - $75+'
        },
        {
          itemContent: '$60 - $150+'
        },
        {
          itemContent: '$10 - $50+'
        }
      ]
    },
    {
      title: 'Transaction fee',
      content: [
        {
          itemContent: '0%'
        },
        {
          itemContent: '3%'
        },
        {
          itemContent: '0%'
        },
        {
          itemContent: '0%'
        }
      ]
    },
    {
      title: 'Project manager',
      subtitle: 'at no extra cost',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Product spec and wireframing',
      subtitle: 'at no extra cost',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Experienced Billsby developers',
      subtitle: 'reducing project timescales',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: '20% overage guarantee',
      subtitle: 'protects against budget overruns',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Hosting and maintenance plans',
      subtitle: 'from $100 per month',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Real-time communication',
      subtitle: 'via slack',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    }
  ]
}

const sixthSectionContent = {
  title: <span>Affordable solutions <br/> 20% overage protection.<br/> No hidden fees.</span>,
  description: [
    {
      text: "Because we want you to get started with Billsby as quickly as possible, we've made Billsby Pro Development Services really affordable. Plus, with 20% overage protection, even if your project takes a little longer than we estimated, you won't have to pay more."
    },
    {
      text: "When everything is setup, we offer fixed price maintenance and hosting plans too."
    }
  ],
  buttons: [
    {
      url: '/',
      buttonText: 'Get in touch for a free consultation',
      buttonColor: 'orange',
      getInContactButton: true
      // intercom: true,
      // itercomBottom: true
    }
  ],
  image: 'pro-development-section-6.png',
  imageName: 'pro-development image',
  imagePosition: 'full-right',
  textColor: '#000',
  backgroundColor: '#f3f5fb',
  backgroundImage: require('../../images/pro-development-section-6@2x.png'),
  backgroundPosition: 'right',
  sectionFlowers: true
}

// const swiperContent = [
//   {
//     cusLogo: 'velocity@2x.png',
//     alttextLogo: 'Logo',
//     cusImage: 'coffee@2x.png',
//     alttextBanner: 'Logo',
//     cusText: 'Increasing customer retention by 72%',
//   },
//   {
//     cusLogo: 'velocity@2x.png',
//     alttextLogo: 'Logo',
//     cusImage: 'coffee@2x.png',
//     alttextBanner: 'Logo',
//     cusText: 'Building a subscription coffee business overnight',
//   },
//   {
//     cusLogo: 'velocity@2x.png',
//     alttextLogo: 'Logo',
//     cusImage: 'coffee@2x.png',
//     alttextBanner: 'Logo',
//     cusText: 'Improving enterprise customer experience',
//   },
//   {
//     cusLogo: 'velocity@2x.png',
//     alttextLogo: 'Logo',
//     cusImage: 'coffee@2x.png',
//     alttextBanner: 'Logo',
//     cusText: 'Increasing customer retention by 72%',
//   },
//   {
//     cusLogo: 'velocity@2x.png',
//     alttextLogo: 'Logo',
//     cusImage: 'coffee@2x.png',
//     alttextBanner: 'Logo',
//     cusText: 'Increasing customer retention by 72%',
//   },
// ]

const ProDevelopment = () => {
  return (
    <Layout>
      <SEO 
        title="Billsby Pro Development Services | Billsby | Powerful, customizable subscription billing software" 
        description="Build bespoke subscription billing and recurring payments solutions on top of our flexible subscription management platform with development from $25 per hour." 
        url="https://www.billsby.com/pro/development"
      />
      
      <>
      <TwoColumn content={bannerContent}/>
      <TwoColumn content={subscriptionContent}/>
      <Process content={processContent}/>
      <TwoColumn content={fourthSectionContent}/>
      {/* <ComparisonTable content={comparisonTableContent}/> */}
      <TwoColumn content={sixthSectionContent}/>
      {/* <SectionSwiper content={swiperContent} /> */}
      </>
    </Layout>
  )
}

export default ProDevelopment
